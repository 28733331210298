import * as React from 'react';

import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage: React.FC = () => (
  <Layout pathname="/404">
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      Could not find a page with this URL. <Link to="/">Back to homepage</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
